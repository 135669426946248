@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

@font-face {
  font-family: 'Roboto';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
}
/* Set content font-families */
.ql-font-Roboto {
  font-family: 'Roboto' !important;
}
.ql-font-Raleway {
  font-family: 'Raleway' !important;
}
.ql-font-montserrat {
  font-family: 'Montserrat' !important;
}
.ql-font-lato {
  font-family: 'Lato' !important;
}
.ql-font-rubik {
  font-family: 'Rubik' !important;
}
