.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(245, 247, 250, 1) 0%, rgba(230, 233, 237, 1) 100%);
  z-index: 2;
  font-size: 32px;
}
.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
  will-change: transform;
}
.loader div span {
  display: block;
  width: 0.5em;
  height: 0.15em;
  background: var(--CHA-Blue_primary);
  -webkit-border-radius: 0.08em;
  border-radius: 0.08em;
}
.loader .item-1 {
  margin-left: 11.313708496px;
  margin-top: -11.313708496px;
  animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
.loader .item-1 span {
  transform: rotate(45deg) translateZ(0);
}
.loader .item-2 {
  margin-left: 0px;
  margin-top: -16px;
  animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
.loader .item-2 span {
  transform: rotate(180deg) translateZ(0);
}
.loader .item-3 {
  margin-left: -11.313708496px;
  margin-top: -11.313708496px;
  animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
.loader .item-3 span {
  transform: rotate(135deg) translateZ(0);
}
.loader .item-4 {
  margin-left: -16px;
  margin-top: 0px;
  animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
.loader .item-4 span {
  transform: rotate(270deg) translateZ(0);
}
.loader .item-5 {
  margin-left: -11.313708496px;
  margin-top: 11.313708496px;
  animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
.loader .item-5 span {
  transform: rotate(225deg) translateZ(0);
}
.loader .item-6 {
  margin-left: 0px;
  margin-top: 16px;
  animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
.loader .item-6 span {
  transform: rotate(360deg) translateZ(0);
}
.loader .item-7 {
  margin-left: 11.313708496px;
  margin-top: 11.313708496px;
  animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
.loader .item-7 span {
  transform: rotate(315deg) translateZ(0);
}
.loader .item-8 {
  margin-left: 16px;
  margin-top: 0px;
  animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
.loader .item-8 span {
  transform: rotate(450deg) translateZ(0);
}

@keyframes spin_full {
  0% {
    transform: rotate(0deg) translateZ(0);
  }
  100% {
    transform: rotate(360deg) translateZ(0);
  }
}

.dropzone-isDragActive {
  border-color: var(--CHA-Blue_primary) !important;
}
