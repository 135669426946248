/* SUIT */
@font-face {
  font-family: 'SUIT-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* --CHA-Blue_primary: #d92f8a; */
  /* --CHA-Blue_primary: #5ac467; */
  --CHA-Blue_primary: #333843;
  --CHA-Blue_second: #004085;
  --Palette-DarkGray-80: #333843;
  --Palette-DarkGray-50: #5c5a5d;
  --Palette-Gray-70: #6a6a6a;
  --Palette-Gray-10: #f4f4f4;
  --Palette-DarkGray-20: #e0e2e7;
  --Palette-Text: #414042;
  --Palette-Gary-5: #eeeeee;
  --Palette-Gray-50: #a4a4a4;
  --cha_yellow: #f5ce3e;
  --gray-bg: #e9ecef;
  --skyblue-bg: #e1e8ff;
  --blue-text: #0c5091;
  --table-text-gary: #333843;
  --table-sub-gary: #6b6b6b;
  --report-pass-blue-text: #3086ec;
  --report-pass-blue-bg: #3086ec14;
  /* --report-fail-pink-text: #d92f8a; */
  --report-fail-pink-text:#5ac467;
  --report-fail-pink-bg: #d92f8a14;
  --report-myScore-pink-bg: #d92f8a0d;
  --report-liberal-title: '#111928';
  --report-liberal-description: '#637381';
  --report-teacher-tabla-average: #a0aaba14;
  --report-background-gary-58595B: #58595b;
}
.color-primary {
  color: var(--CHA-Blue_primary) !important;
}
.color-second {
  color: var(--CHA-Blue_second) !important;
}
.color-text {
  color: var(--Palette-Text) !important;
}
.bg-transparent {
  background: transparent !important;
}
.bg-primary {
  background-color: var(--CHA-Blue_primary) !important;
}
.bg-second {
  background-color: var(--CHA-Blue_second) !important;
}
.bg-cardZoomOption {
  background-color: #212b3610;
}
.bg-tooltip {
  background-color: var(--Palette-DarkGray-80) !important;
}
.border-primary {
  border-color: var(--CHA-Blue_primary) !important;
}
.bg-report-bg {
  background: var(--gray-bg) !important;
}
.bg-skyblue-bg {
  background: var(--skyblue-bg) !important;
}
.color-skyblue-bg {
  color: var(--skyblue-bg) !important;
}
.color-blue-text {
  color: var(--blue-text) !important;
}
.bg-blue-text {
  background-color: var(--blue-text) !important;
}
.color-transparent {
  color: transparent !important;
}

.color-table-gary {
  color: var(--table-text-gary) !important;
}
.color-table-sub-gary {
  color: var(--table-sub-gary) !important;
}
.bg-table-teacher-gray {
  background: var(--report-teacher-tabla-average) !important;
}

.color-report-pass-blue {
  color: var(--report-pass-blue-text) !important;
}
.bg-report-pass-blue {
  background: var(--report-pass-blue-bg) !important;
}
.color-report-fail-blue {
  color: var(--report-fail-pink-text) !important;
}
.bg-report-fail-blue {
  background: var(--report-fail-pink-bg) !important;
}

.bg-report-myScore-pink {
  background: var(--report-myScore-pink-bg) !important;
}

.color-liberal-title {
  color: var(--report-liberal-title) !important;
}
.color-liberal-description {
  color: var(--report-liberal-description) !important;
}

body {
  /* font-family: 'SUIT'; */
  font-family: 'Noto Sans KR';
}
.color-primary {
  color: var(--CHA-Blue_primary) !important;
}
.bg-primary {
  background-color: var(--CHA-Blue_primary) !important;
}
.bg-gary-50 {
  background-color: var(--Palette-Gray-50) !important;
}
.bg-gary-10 {
  background-color: var(--Palette-Gray-10) !important;
}
.border-primary {
  border-color: var(--CHA-Blue_primary) !important;
}

.--Palette-DarkGray-80 {
  color: var(--Palette-DarkGray-80) !important;
}
.--Palette-DarkGray-50 {
  color: var(--Palette-DarkGray-50) !important;
}
.bg-DarkGray-20 {
  background-color: var(--Palette-DarkGray-20);
}
.color-DarkGray-20 {
  color: var(--Palette-DarkGray-20);
}
.--Palette-DarkGray-60 {
  color: #667085 !important;
}
.--Palette-Gray-50 {
  color: #a4a4a4 !important;
}
.--Palette-DarkGray-80 {
  color: #333843 !important;
}
.--Palette-Gray-70 {
  color: #6a6a6a !important;
}
.--Palette-Gray-80 {
  color: #333843 !important;
}
.--cha_yellow {
  color: var(--cha_yellow, #f5ce3e);
}
.bg-58595B {
  background: var(--report-background-gary-58595B) !important;
}
/* .font-suit {
  font-family: 'SUIT-Regular';
} */

.font-openSans {
  font-family: 'Open Sans', sans-serif;
}

.h1 {
  color: #5c5d5d;
  font-size: 25px;
}

.fz-32 {
  font-size: 2rem;
}
.fz-26 {
  font-size: 1.675rem;
}
.fz-25 {
  font-size: 1.5625rem;
}
.fz-24 {
  font-size: 1.5rem;
}
.fz-22 {
  font-size: 1.375rem;
}
.fz-20 {
  font-size: 1.25rem;
}
.fz-18 {
  font-size: 1.125rem;
}
.fz-16 {
  font-size: 1rem;
}
.fz-15 {
  font-size: 0.9375rem;
}
.fz-14 {
  font-size: 0.875rem;
}
.fz-12 {
  font-size: 0.75rem;
}
.lh-16 {
  line-height: 16px;
}
.lh-22 {
  line-height: 22px;
}
.lh-24 {
  line-height: 24px;
}
.lh-26 {
  line-height: 26px;
}

.h2 {
  color: #5c5d5d;
  font-size: 18px;
}
.h3-large {
  color: #5c5d5d;
  font-size: 18px;
}
.body {
  color: #5c5d5d;
  font-size: 16px;
}
.body-sub {
  color: #5c5d5d;
  font-size: 14px;
}
.tiny {
  color: #5c5d5d;
  font-size: 10px;
}

.text-gray1 {
  color: #333843;
}
.text-gray2 {
  color: #5c5d5d;
}
.text-blue1 {
  color: #0d6efd;
}
.Yellow-500 {
  color: #ffc228;
}
.Blue-500 {
  color: #1859ff;
}
.danger-redText {
  color: #e90f0f;
}
.Blue-600 {
  color: #1243bf;
}
.Blue_primary {
  background: var(--CHA-Blue_primary);
}
.text-blue-primary {
  color: #13426b !important;
}
.btn-blue-primary {
  &:hover {
    background: #13426b !important;
    color: #fff !important;
  }
}

.CHA_LIGHT {
  background: #ebf1fa !important;
}
.CHA_BLUE {
  background: #13426b !important;
}

/* openSans */
.noto-sans {
  font-family: 'Open Sans', sans-serif;
}

.fw-500 {
  font-weight: 500;
}
.w-full {
  width: 100% !important;
}
.w-half {
  width: 50% !important;
}
.h-35 {
  height: 35px !important;
}
.h-full {
  height: 100% !important;
}
.h-400 {
  height: 400px;
}
.h-800 {
  height: 800px;
}
.h-345 {
  height: 345px;
}
.h-350 {
  height: 350px;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.wh-3_2rem {
  width: 3.2rem !important;
  height: 3.2rem !important;
}
.w4_625-h2_563rem {
  width: 3.6rem !important;
  height: 2.2rem !important;
}

.w3_563-h-1_875rem {
  width: 3.563rem !important;
  height: 1.875rem !important;
}

.px-26 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.popupBtn {
  /* border: 1px solid #dfe4ea; */
}

.vertical-2px {
  vertical-align: 2px;
}
.progressbar-filter {
  & > .progress-bar {
    background-color: #3086ec !important;
  }
  & svg {
    width: 200px;
  }
}

.wh-20 {
  width: 20px;
  height: 20px;
}

.max-h-500 {
  max-height: 500px !important;
}
.max-h-300 {
  max-height: 300px !important;
}
.m-0-auto {
  margin: 0 auto;
}

.wh-11 {
  width: 11px;
  height: 11px;
}

.report-department-basis {
  flex-basis: 160px;
}

.w-33per {
  width: 33% !important;
}

.base-color {
  color: #5ac467;
}

.ch-bg1 {
  background-color: #41736f;
}

.ch-bg2 {
  background-color: #5d733f;
}

.ch-bg3 {
  background-color: #6a502c;
}

.ch-bg4 {
  background-color: #64242e;
}

.ch-bg5 {
  background-color: #ffcc00; /* 노란색 */
}

.ch-bg6 {
  background-color: #9933cc; /* 보라색 */
}

.ch-bg7 {
  background-color: #0066cc; /* 파란색 */
}

.ch-bg8 {
  background-color: #cc6600; /* 갈색 */
}

.ch-bg9 {
  background-color: #339966; /* 연녹색 */
}

.ch-bg10 {
  background-color: #cc0000; /* 빨간색 */
}

.ch-bg11 {
  background-color: #66cccc; /* 청록색 */
}

.ch-bg12 {
  background-color: #996633; /* 카라멜 색 */
}

.ch-bg13 {
  background-color: #3333cc; /* 진파란색 */
}

.ch-bg14 {
  background-color: #ff9900; /* 주황색 */
}

.ch-bg15 {
  background-color: #660066; /* 어두운 보라색 */
}

.ch-bg16 {
  background-color: #0099cc; /* 스카이 블루 */
}

.ch-bg17 {
  background-color: #996600; /* 어두운 갈색 */
}

.ch-bg18 {
  background-color: #339933; /* 녹색 */
}

.ch-bg19 {
  background-color: #cc0033; /* 진빨간색 */
}

.ch-bg20 {
  background-color: #33cccc; /* 연한 청록색 */
}

.ch-bg21 {
  background-color: #ff6699; /* 핑크색 */
}

.ch-bg22 {
  background-color: #6600cc; /* 진보라색 */
}

.ch-bg23 {
  background-color: #003366; /* 남색 */
}

.ch-bg24 {
  background-color: #ffcc66; /* 연주황색 */
}

.ch-bg25 {
  background-color: #009933; /* 초록색 */
}

.ch-bg26 {
  background-color: #cc3399; /* 진핑크 */
}

.ch-bg27 {
  background-color: #333399; /* 어두운 남색 */
}

.ch-bg28 {
  background-color: #ff9966; /* 살구색 */
}

.ch-bg29 {
  background-color: #663300; /* 다크 브라운 */
}

.ch-bg30 {
  background-color: #006633; /* 어두운 녹색 */
}

.ch-bg31 {
  background-color: #990033; /* 와인색 */
}

.ch-bg32 {
  background-color: #669999; /* 회녹색 */
}

.ch-bg33 {
  background-color: #ff9999; /* 연분홍색 */
}

.ch-bg34 {
  background-color: #660033; /* 다크 퍼플 */
}

.ch-bg35 {
  background-color: #336699; /* 블루 그레이 */
}

.ch-bg36 {
  background-color: #ff6633; /* 주황-빨강 */
}

.ch-bg37 {
  background-color: #006666; /* 진한 청록색 */
}

.ch-bg38 {
  background-color: #990066; /* 다크 핑크 */
}

.ch-bg39 {
  background-color: #99cc33; /* 연녹-노랑 */
}

.ch-bg40 {
  background-color: #003300; /* 어두운 녹색 */
}

.ch-bg41 {
  background-color: #cccc33; /* 노랑-카키 */
}

.ch-bg42 {
  background-color: #9900cc; /* 진한 보라색 */
}

.ch-bg43 {
  background-color: #330033; /* 다크 보라 */
}

.ch-bg44 {
  background-color: #3399cc; /* 밝은 블루 */
}

.ch-bg45 {
  background-color: #ff3300; /* 빨강-주황 */
}

.ch-bg46 {
  background-color: #0066ff; /* 밝은 파랑 */
}

.ch-bg47 {
  background-color: #993333; /* 브라운-레드 */
}

.ch-bg48 {
  background-color: #666600; /* 어두운 황색 */
}

.ch-bg49 {
  background-color: #33cc33; /* 라임 그린 */
}

.ch-bg50 {
  background-color: #333333; /* 다크 그레이 */
}
