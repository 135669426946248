.jandiItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 3px;
  &:last-child {
    margin-right: 0;
  }
}

.jandiDone {
  background-color: var(--CHA-Blue_primary);
  color: white;
  font-size: 12px;
}
.jandiNotDone {
  background-color: #f4f4f4;
  color: white;
  font-size: 12px;
}

.jandiProgress {
  border: 1px solid var(--CHA-Blue_primary);
  background-color: '#f4f4f';
  color: var(--CHA-Blue_primary);
  font-size: 12px;
}
