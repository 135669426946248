.react-calendar {
  /* background: red; */
}

.react-calendar__navigation {
  /* font-family: 'SUIT'; */
  font-size: 14px;
  display: flex;
  color: #333843;
  height: 58px;
  & button {
    border: none;
    background: none;
  }
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  padding: 20px;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  padding: 20px;
}
.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  font-size: 10px;
  color: #333843;
}
.react-calendar__month-view__days {
  text-align: center;
}
.react-calendar__tile.react-calendar__month-view__days__day {
  border: none;
  background: none;
  font-size: 14px;
  color: #333843;
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.start-calendar {
  background: red !important;
}

.end-calendar {
  background: blue !important;
}

.calendar-active {
  background: green !important;
}

.calendar-start {
  background: red;
}
.calendar-end {
  background: blue;
}
